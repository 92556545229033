import React from 'react';
import GamesConnectPageTemplate from '../../../components/Connect/Games';

const subtitles = {
  A: (
    <>
      Tintash provides managed remote teams from around the world. These teams work closely with you
      for game design, art asset creation, and engineering.
    </>
  ),
  B: 'Leverage the top game development talent to elevate your project, helping you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <GamesConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="INDUSTRY LEADING GAME DEVELOPMENT TEAMS"
      subtitles={subtitles}
      metaTitle="Top Game Design and Development Company | Tintash"
      metaDescription="Tintash is an industry leading game design and development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Games/Game.Design.And.Development"
    />
  );
}

export default index;
